/* eslint-disable no-use-before-define */
import BaseController from "controllers/base"
// import $ from "jquery"

class Controller extends BaseController {
  call() {
    super.call()

    loadInputFields.call(this)
  }
}

const loadInputFields = function () {
  // eslint-disable-next-line no-undef
  // const nsw_gov_holidays = gon.nsw_gov_holidays.map(date => ({ date: date[0], name: date[1] }))
  this.root.find(".input-daterange").datepicker({
    format: "dd/mm/yyyy",
    weekStart: 1,
    todayHighlight: true,
    todayBtn: true,
    // beforeShowDay(date) {
    //   const date_str = date.toISOString().slice(0, 10)
    //   const find_holiday = nsw_gov_holidays.find(holiday => holiday.date === date_str)
    //   if (find_holiday) {
    //     return { content: `${date.getDate()} ${find_holiday.name}` }
    //   }
    //   return {}
    // }
  })
}

export default Controller
