// Can put common controller stuff in here :D
import Raven from "raven-js"

import * as initializers0 from "../initializers/alerts.js"; import * as initializers1 from "../initializers/input_components/datepicker.js"; import * as initializers2 from "../initializers/input_components/radio_buttons.js"; import * as initializers3 from "../initializers/input_components/tooltips.js"; import * as initializers4 from "../initializers/sweetalert.js"; let initializers = [initializers0, initializers1, initializers2, initializers3, initializers4]

const runInitializers = function () {
  if (initializers === undefined) { return }

  initializers.forEach(initializer => initializer.default(this))
}

const Controller = function () {
  this.init = () => {
    try {
      runInitializers.call(this)
      this.call()
    } catch (e) {
      if (gon.env === "development") {
        console.error(e.stack)
      } else {
        Raven.captureException(e)
      }
    }
  }
}

export default Controller
