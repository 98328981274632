import { Calendar } from "@fullcalendar/core/"
import dayGridPlugin from "@fullcalendar/daygrid"
import BaseController from "controllers/base"
import $ from "jquery"
import * as d3 from "d3"

class Controller extends BaseController {
  call() {
    super.call()
    $.ajax({
      url: "/calendar/approved_requests",
      dataType: "json",
      async: false,
      success(data) {
        // eslint-disable-next-line no-use-before-define
        initCalendar(data)
      }
    })
  }
}

const initCalendar = function (approved_requests_list) {
  let event_list = []
  let requester_list = []
  for (const request of approved_requests_list) {
    requester_list.push(request.requester)
  }
  var requester_color = d3.scaleOrdinal()
    .domain(requester_list)
    .range(d3.schemePaired);
  for (const request of approved_requests_list) {
    event_list.push({
      title: request.requester,
      start: request.commencing.split("T")[0],
      end: request.ending.split("T")[0],
      backgroundColor: requester_color(request.requester)
    })
    console.log(requester_color(request.requester));
  }

  const calendarEl = document.getElementById("calendar")
  const calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin],
    events: event_list
  })
  calendar.render()
}

export default Controller
