import "../stylesheets/application"

import Raven          from "raven-js"
import configureRaven from "../lib/raven"
import boot           from "../lib/boot"
import axios from "axios"
import 'bootstrap'

require("@rails/ujs").start()
require("d3-scale-chromatic")

$(() => {
  configureAxios(axios)
  configureRaven()

  Raven.context(() => {
    boot.init()
  })
})

const configureAxios = axios => {
  axios.defaults.headers.common["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0].getAttribute("content")
  axios.defaults.headers.common.Accept = "application/json"
}
