import $ from "jquery";
import "bootstrap-notify";

function railsBootstrpFlashType(railsType) {
  switch (railsType) {
    case "notice":
      return "success"
    case "alert":
      return "danger"
    default:
      return "info"
  }
}

export default function (controller) {
  $(document).ready(function() {
    if ($(".hide-popup-alert").length === 0) {
      const flashMsgs = gon.flash
      flashMsgs.forEach(flash=> {
        const notify = $.notify({
          title: flash[0],
          message: flash[1],
        }, {
          type: railsBootstrpFlashType(flash[0]),
          template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} sentia-hive" role="alert">' +
                      '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                      '<span data-notify="icon"></span> ' +
                      '<div data-notify="title"><strong>{1}</strong></div> ' +
                      '<div data-notify="message">{2}</div>' +
                      '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                      '</div>' +
                      '<a href="{3}" target="{4}" data-notify="url"></a>' +
                    '</div>' 
        });
      }) 
    }
  })
}