import FlashMessages from "mixins/flash";

class Controller {
  call() {
    document.controller = this

    // EXAMPLE
    // this.flash = { options: { message: 'You have updated your account settings, well done mate.', url: 'http://www.google.com', icon: 'fa fa-check' }, settings: { type: 'info', placement: { from: 'top', align: 'center' } } };

    FlashMessages.call(this, this.flash);
  }
}


export default Controller
