let session = {}

function loadSession() {
  const { user } = gon
  const { env } = gon
  const { env_vars } = gon

  session = {
    user,
    isProduction() {
      return env === "production"
    },
    isDevelopment() {
      return env === "development"
    },
    isTest() {
      return env === "test"
    }
  }
}

export { session, loadSession }
