import Raven from "raven-js"

function configureRaven() {
  const { env } = gon
  const { env_vars } = gon

  const config = {
    release:      env_vars.version,
    environment:  "production",
    debug:        env == "development"
  }

  if ((env == "development") || (env == "staging")) {
    console.log(`Raven config`)
    console.log(config)
  }

  Raven.config(env_vars.raven_dsn, config).install()
}

export default configureRaven
