import BaseController from "controllers/base"
import $ from "jquery"

class Controller extends BaseController {
  call() {
    super.call()

    setFlowerPosition.call(this)
  }
};

const setFlowerPosition = function() {
  $( window ).on("load resize", (e) => {
    const $flower = this.root.find(".flower")
    if ($flower.length){
      const windowWidth = e.currentTarget.outerWidth
      const flowerHeight = $flower.height()
      const $footer = this.root.find("footer")
      const footerTop = $footer.offset().top
      if (windowWidth > 992) {
        $flower.css("top", `${footerTop-flowerHeight+10}px`)
      } else {
        $flower.css("top", `${footerTop}px`)
      }
    }
  })
}

export default Controller;