import BaseController from "controllers/base"
import $ from "jquery"
import swal from "sweetalert2"

class Controller extends BaseController {
  call() {
    super.call()
    // actionPopUp.call(this)
  }
}

const actionPopUp = function () {
  console.log(this.root)
  const $approveBtns = this.root.find(".request_approve")
  console.log($approveBtns)
  $approveBtns.on("click", e => {
    e.preventDefault()
    console.log(e.currentTarget)
    swal.fire({
      type: "success",
      title: "Approve",
      text: "click!",
      confirmButtonText: "Ok"
    })
  })
}

export default Controller